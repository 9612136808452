import React from 'react'
import clsx from 'clsx'
import { Container } from '@ui/components/Container'

export function HeroBasic({
  className,
  title,
  description,
}: {
  className?: string
  title?: React.ReactNode
  description?: React.ReactNode
}) {
  return (
    <div className={clsx('bg-off-white py-20', className)}>
      <Container>
        <div className="flex flex-col items-center justify-center gap-4">
          {title && <h1 className="font-montserrat h1 text-center">{title}</h1>}
          {description && (
            <h2 className="body-hero text-center">{description}</h2>
          )}
        </div>
      </Container>
    </div>
  )
}
