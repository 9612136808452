import Link from 'next/link'
import clsx from 'clsx'
import { QRCodeSVG } from 'qrcode.react'
import { Container } from '@ui/components/Container'
import { ButtonAppStore, ButtonGooglePlay } from '@ui/components/buttons'

export const HeroDownload = ({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}) => {
  return (
    <div className={clsx('bg-off-white py-20', className)}>
      <Container>
        <div className="flex flex-col items-center justify-center gap-8 lg:flex-row">
          <div className="flex flex-col items-center justify-center gap-8 lg:w-1/2 lg:items-start">
            <h1 className="font-montserrat h1 text-center lg:text-left">
              Download the app
            </h1>
            <div className="flex flex-row items-start justify-center gap-4">
              <ButtonAppStore />
              <ButtonGooglePlay />
            </div>
            <p className="body-1 text-center lg:max-w-sm lg:text-left">
              Visit{' '}
              <Link className="text-blueberry-700 underline" href="/download">
                atm.com/download
              </Link>{' '}
              on your phone or use your camera to scan the QR code.
            </p>
            <div className="shadow-blueberry-900/20 rounded-xl bg-white p-4 shadow-lg">
              <QRCodeSVG value="https://www.atm.com/download" />
            </div>
          </div>
          <div className="flex flex-col items-start justify-center gap-4">
            {children}
          </div>
        </div>
      </Container>
    </div>
  )
}
