import React from 'react'
import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import { Button } from '@ui/components/buttons'
import { InstantCashDisclosure } from '@ui/components/Disclosure'

export const HeroHome = ({
  className,
  children,
  title,
  description,
  cta,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClickEvent = () => {},
}: {
  className?: string
  children?: React.ReactNode
  title: React.ReactNode
  description: React.ReactNode
  cta?: string
  handleClickEvent?: () => void
}) => {
  return (
    <div className={clsx('bg-white', className)}>
      <Container>
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex-0 order-2 mb-4 flex flex-col justify-center gap-4 py-4 lg:order-1 lg:w-1/2 lg:py-8">
            <h1 className="font-montserrat h1">{title}</h1>
            <p className="h3">{description}</p>
            <div className="flex">
              <Button
                onClick={handleClickEvent}
                variant="blueberryLg"
                href="/sign-up"
              >
                {cta || <>Get Started</>}
              </Button>
            </div>
            <InstantCashDisclosure className="mt-2" />
          </div>
          <div className="order-1 lg:order-2 lg:w-1/2">{children}</div>
        </div>
      </Container>
    </div>
  )
}
