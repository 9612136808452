import Image from 'next/image'
import React from 'react'
import { Container } from '@ui/components/Container'
import { Button } from '@ui/components/buttons'

export const HeroInstantCash = ({
  className,
  title,
  description,
  cta,
  disclosure,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClickEvent = () => {},
}: {
  className?: string
  title: React.ReactNode
  description: React.ReactNode
  cta?: string
  disclosure?: React.ReactNode
  handleClickEvent?: () => void
}) => {
  return (
    <div className={className}>
      <Container>
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex flex-col justify-center gap-5 py-8 md:py-12 lg:w-1/2 xl:py-20">
            <h1 className="h1 font-montserrat">{title}</h1>
            <p className="h3">{description}</p>
            <div className="mt-2 flex">
              <Button
                onClick={handleClickEvent}
                variant="blueberryLg"
                href="/auth/register"
              >
                {cta || <>Get Started</>}
              </Button>
            </div>
            {disclosure && (
              <div className="content-disclosure text-dolphin-900">
                {disclosure}
              </div>
            )}
          </div>
          <div className="flex items-center justify-center lg:w-1/2">
            <Image
              alt="ATM app"
              src="/img/hero_atm_instant_cash_50@3x.png"
              width={505}
              height={438}
              loading="eager"
              priority={true}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
