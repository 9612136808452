import React from 'react'
import Head from 'next/head'
import { WebsiteLayout } from '@website/components/layout/WebsiteLayout'
import { Meta } from '@website/components/Meta'
import { HeroHome } from '@ui/hero'
import {
  MainBlockTitleUnderline,
  MainBlockATMInstantCash,
  MainBlockHowToStartWithInstantCash,
  MainBlockTrackYourSpending,
  MainBlockWavyWhite,
  MainBlockWavyOffWhite,
  MainBlockHowATMMembersAreUsingInstantCash,
  MainBlockPersonalFinanceScreens,
  MainBlockRewards,
  MainBlockPersonalFinance,
  MainBlockJoinMillionsDownload,
  MainBlockFAQs,
  MainBlockTestimonials,
} from '@ui/main-blocks'
import {
  MainBlockInstantCashImage2,
  MainBlockWealthBoostImage,
  MainBlockTrackYourSpendingImage,
  HomepageATMInstantCashRewardsImage,
} from '@website/components/images'
import { faqs } from '@ui/components/data'

const title = 'ATM Instant Cash™ & Rewards - Earn, Budget, Save & Access Cash*'
const canonical = 'https://www.atm.com/'
const description =
  'Discover how ATM.com helps you earn extra cash, manage your finances, and access instant cash advances when you need them most. Join the Ant Colony and start earning rewards, budgeting smarter, and accessing emergency funds today.'
const image = '/img/og/og-atm-1.png'

export function Index() {
  const ctaUrl = '/sign-up'
  const amount = 50
  const faqList = faqs({ amount })

  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqList.map((faq) => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer,
      },
    })),
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <Meta
          title={title}
          description={description}
          canonical={canonical}
          image={image}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema) }}
        />
      </Head>
      <WebsiteLayout>
        <HeroHome
          title={
            <>
              ATM Instant Cash™{' '}
              <span className="whitespace-pre">& Rewards</span>
            </>
          }
          description={
            <>
              Earn, Budget, Save &{' '}
              <span className="whitespace-pre">Access Cash*</span>
            </>
          }
          cta="Get Started"
        >
          <HomepageATMInstantCashRewardsImage className="flex h-full w-full items-center justify-center" />
        </HeroHome>
        <MainBlockTitleUnderline variant="off-white" />
        <MainBlockATMInstantCash className="pb-0">
          <MainBlockInstantCashImage2 className="flex h-full w-full items-center justify-center" />
        </MainBlockATMInstantCash>
        <MainBlockWavyOffWhite />
        <MainBlockRewards className="pt-10">
          <MainBlockWealthBoostImage className="flex h-full w-full items-center justify-center" />
        </MainBlockRewards>
        <MainBlockPersonalFinanceScreens />
        <MainBlockHowToStartWithInstantCash className="pb-0" />
        <MainBlockWavyWhite />
        <MainBlockHowATMMembersAreUsingInstantCash className="pt-10" />
        <MainBlockTrackYourSpending className="bg-blueberry-500 text-white">
          <MainBlockTrackYourSpendingImage className="flex h-full w-full items-end justify-center" />
        </MainBlockTrackYourSpending>
        <MainBlockPersonalFinance />
        <MainBlockFAQs faqs={faqList} />
        <MainBlockTestimonials />
        <MainBlockJoinMillionsDownload ctaUrl={ctaUrl} />
      </WebsiteLayout>
    </>
  )
}

export default Index
