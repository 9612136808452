import React from 'react'
import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import { ColonyAntsMobile, ColonyAntsDesktop } from '@ui/components/Svg'

export function HeroColony({
  className,
  title,
  description,
}: {
  className?: string
  title?: React.ReactNode
  description?: React.ReactNode
}) {
  return (
    <div
      className={clsx(
        'bg-off-white flex items-center overflow-hidden',
        className,
      )}
    >
      <Container className="relative py-20">
        <div className="flex flex-col items-center justify-center gap-4">
          {title && (
            <h1 className="font-montserrat h1 mx-auto max-w-[600px] text-center">
              {title}
            </h1>
          )}
          {description && (
            <h2 className="body-hero text-center">{description}</h2>
          )}
        </div>
        <div
          className={
            (clsx(),
            '-mb-20 flex items-end justify-center lg:relative lg:-mt-36')
          }
        >
          <ColonyAntsMobile className="block lg:hidden" />
          <ColonyAntsDesktop className="hidden lg:block" />
        </div>
      </Container>
    </div>
  )
}
